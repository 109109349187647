<template>
	<section class="join-volunteer">
		<div class="max-width-1000">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item to="/join">加入金枝</el-breadcrumb-item>
				<el-breadcrumb-item>金枝志工團</el-breadcrumb-item>
			</el-breadcrumb>
			<br>
			<quote-title label="金枝志工團" />
			<br>
			<article>
				<p>不分男女老少，不管您是喜歡金枝的戲迷，或是對表演藝術有興趣、有熱情的文藝青年，只要您願意和我們一起服務民眾，都歡迎加入金枝志工團。</p>
				<p>一、服務內容
					<ol>
						<li>協助演出現場執行，讓藝術種子在台灣遍地開花。</li>
						<li>為演出活動留下腳印，以文字或攝影紀錄皆可。</li>
					</ol>
				</p>
				<p>二、服務地點／時間
					<ol>
						<li>地點：為方便志工，服務地點以志工所居住該縣市為主。（也歡迎志工跨縣市服務喔！）</li>
						<li>時間：服務時間視排定的演出行程而定。（金枝走演專案活動收到志工填妥的報名表後，會整理建檔，並在各縣市演出前一個月與在地志工聯繫，確認可服務時間。）</li>
					</ol>
				</p>
				<p>三、志工福利
					<ol>
						<li>金枝演社會為您開立服務證明，讓您的人生履歷金歙歙。</li>
						<li>實際參與劇場活動，打開人生視野。</li>
						<li>購買金枝演社主辦的節目展演票卷，以及金枝出版品或紀念品，都可享有優惠折扣。</li>
						<li>欣賞金枝好戲一齣，補充心靈糧食。</li>
					</ol>
				</p>
				<p>完成線上填表加入後，金枝演社將有專人以電話或E-mail通知確認。</p>
			</article>
			<br>
			<article>
				<h2 class="theme_text">我要加入</h2>
				<hr>
				<br>
				<member-form-readonly />
				<volunteer-form ref="ruleForm" />
				<br>
				<div class="center">
					<el-button type="theme" round @click="handleRigister()">立即報名</el-button>
				</div>
			</article>
		</div>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
	components: {
		MemberFormReadonly: () => import("@/components/join/member-form-readonly"),
		VolunteerForm: () => import("@/components/join/volunteer-form")
	},
	methods: {
		...mapActions("auth", ["_joinVolunteer"]),
		async handleRigister() {
			try {
				const message = await this._joinVolunteer(await this.$refs["ruleForm"].validate());
				this.$alert(message, {
					type: "success",
					dangerouslyUseHTMLString: true
				}).then(async () => {
					this.$router.push("/join");
				});
			} catch (error) {
				this.$message.error(error);
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.join-volunteer {
	padding: 50px 5%;
}
</style>